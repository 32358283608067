<template>
  <div>
    <v-card>
      <v-card-title class="blue darken-1 text-white">Nueva Solicitud</v-card-title>
      <v-card-text>
        <div class="row align-items-center">
          <div class="col-md-9 black--text">
            Usted puede digitar directamente el material, el campo Categoría se completa de forma automática
          </div>
          <div class="col-md-2">
            <p style="text-align:right;margin-top:25px">
              Agregar Fila
            </p>
          </div>
          <div class="col-md-1 text-left">
            <v-btn class="mx-2" fab dark small color="indigo" @click="agregarFila">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-1 mt-2"
                  fab
                  small
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  @click="agregarFila"
                >
                  <v-icon large style="color:white;font-size:22px">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
<span>Agregar Fila</span>
</v-tooltip> -->
          </div>
        </div>
      </v-card-text>
      <v-alert border="left" outlined text :type="alert.tipo" v-if="alert.mostrar">
        <v-row>
          <v-col>
            <h4>{{ alert.mensaje }}</h4>
          </v-col>
          <v-col class="text-right">
            <v-btn :color="alert.color_boton" elevation="2" small dark @click="cerrar_alert">Cerrar</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="alert.errores.length > 0">
          <v-col>
            <ul>
              <li v-for="e in alert.errores" :key="e">{{ e }}</li>
            </ul>
          </v-col>
        </v-row>
      </v-alert>

      <!-- NUEVO CODIGO -->
      <!-- <pre>{{ data }}</pre> -->
      <!--<pre>{{ user }}</pre>-->
      <v-card-text>
        <spinner v-if="spinner"></spinner>
        <div class="alert" v-if="pendientes.length > 0">
          <h3>Estimado usuario:</h3>
          <p>Le informamos que en el sistema se tiene registro de las siguientes solicitudes pendientes:
          </p>
          <ul>
            <li v-for="pen in pendientes" :key="pen">
              Folio <strong>{{ pen.folio }}</strong>, material <strong>{{ pen.material }}</strong>, con fecha de solicitud <strong>{{ pen.material }}</strong>, en estado <strong>{{ pen.estado }}</strong>, Revisor <strong>{{ pen.revisor }}</strong>
            </li>
          </ul>
          <p>Debido a lo anterior, no es posible registrar una nueva solicitud. Para obtener mayores detalles,
            agradecemos
            comunicarse con el<strong> Revisor del área</strong>.</p>
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center" :style="{ 'min-width': sizeSelect }" v-if="user.wellboats_id == 0">
                  WELLBOAT
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect }">
                  ÁREA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect }">
                  CATEGORÍA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect }">
                  MATERIAL
                </th>
                <th class="text-center" style="min-width:200px">DESCRIPCIÓN</th>
                <th class="text-center" style="min-width:70px">CANTIDAD</th>
                <th class="text-center" style="min-width:150px">
                  ARCHIVO ADJUNTO
                </th>
                <th class="text-center">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td v-if="user.wellboats_id == 0">
                  <v-select v-model="item.wellboats_id" :items="wellboats" item-text="nombre" item-value="id"
                    label="Seleccione Wellboat" color="blue darken-3" persistent-hint :reduce="(item) => item.id"
                    :style="{ 'max-width': sizeSelect }" />
                </td>
                <td>
                  <v-select v-model="item.areas_id" :items="areas" item-text="nombre" item-value="id"
                    label="Seleccione Área" color="blue darken-3" persistent-hint :reduce="(item) => item.id"
                    :style="{ 'max-width': sizeSelect }" />
                </td>
                <td>
                  <v-select v-model="item.categorias_id" :items="categorias" item-text="nombre" item-value="id"
                    label="Seleccione Categoría" color="blue darken-3" persistent-hint :reduce="(item) => item.id"
                    :style="{ 'max-width': sizeSelect }" />
                </td>
                <td>
                  <v-autocomplete v-model="item.materiales_id" :items="items" item-text="cod_nombre" item-value="id"
                    label="Seleccione Material" color="blue darken-3" persistent-hint :reduce="(item) => item.id"
                    :style="{ 'max-width': sizeSelect }" @change="modificarCategoria(item)">
                  </v-autocomplete>
                </td>
                <td>
                  <v-text-field placeholder="Descripción" v-model="item.descripcion" />
                </td>
                <td>
                  <v-text-field placeholder="Cantidad" style="max-width:70px" type="number" v-model="item.cantidad" />
                </td>
                <td>
                  <!-- <v-file-input v-model="item.file" accept=".pdf, .png, .jpg, .jpeg"
                                        label="Seleccione Archivo" style="max-width:150px" /> -->
                  <v-file-input v-model="item.file" label="Seleccione Archivo" style="max-width:150px" />
                </td>
                <td>
                  <!-- <v-btn class="ma-2" color="red" dark :style="{ 'font-size': '16px' }"
                                        @click="eliminarFila(index)">
                                        <i class="material-icons">remove</i>
                                    </v-btn> -->
                  <v-btn class="mx-2" fab dark small color="red" @click="eliminarFila(index)">
                    <i class="material-icons">remove</i>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <!-- NUEVO CODIGO -->

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn @click="volver">Volver</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" @click="guardarSolicitudMaterial">Enviar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    sizeSelect: "300px",
    spinner: false,
    data_header: null,
    activePicker: null,
    botones: [
      { boton: "crear", tooltip: "Agregar Fila" },
      // { boton: 'cargar', tooltip: 'Carga Masiva' },
      //{boton:'eliminar',tooltip:'Borrar Todo'}
    ],
    categorias: [],
    areas: [],
    items: [],
    select_categoria: "",
    validation_message: "",
    color: "green",
    snackbar: false,

    data: [],
    wellboats: [],
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    pendientes: []
  }),
  created() {
    this.agregarFila();
  },
  mounted() {
    this.loadWellboats();
    this.loadCategorias();
    this.loadAreas();
    this.loadMateriales();
  },
  computed: {
    ...mapState(["loading", "base_url", "headers", "headers_file", "user"]),
    filterItem: (data) => {
      return data.select_categoria != ""
        ? data.items.filter(
          (item) => item.categorias_id == data.select_categoria
        )
        : data.items;
    },
  },
  itemLabel() {
    return `nombre: ${this.material.nombre}, codigo_articulo: ${this.material.codigo_articulo} |`;
  },
  methods: {
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
      this.alert.errores = [];
    },
    async loadWellboats() {
      let url = `${this.base_url}wellboats/lista/select`;
      await this.axios.get(url, this.headers).then((response) => {
        this.wellboats = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadCategorias() {
      this.mostrarLoading("Cargando listado de categorias...");
      let url = `${this.base_url}categorias`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.categorias = response.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadAreas() {
      this.mostrarLoading("Cargando listado de áreas...");
      let url = `${this.base_url}areas`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          //this.areas = response.data.filter(d=>this.user.areas_ids.includes(d.id));
          this.areas = response.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadMateriales() {
      this.mostrarLoading("Cargando listado de materiales...");
      let url = `${this.base_url}materiales`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.items = response.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
          this.ocultarLoading();
        });
    },
    volver() {
      this.$router.push("solicitud_materiales");
    },
    async guardarSolicitudMaterial() {
      this.spinner = true;
      this.cerrar_alert();
      let formData = new FormData();

      let url = `${this.base_url}items`;
      this.data.forEach((element, idx) => {
        formData.append(`files[${idx}]`, element.file || "");
        formData.append(`wellboat_id[${idx}]`, element.wellboats_id);
        formData.append(`areas_id[${idx}]`, element.areas_id);
        formData.append(`categorias_id[${idx}]`, element.categorias_id);
        formData.append(`materiales_id[${idx}]`, element.materiales_id);
        formData.append(`descripciones[${idx}]`, element.descripcion);
        formData.append(`cantidades[${idx}]`, element.cantidad);
      });

      if (this.data.length > 0) {
        formData.append("users_id", this.user.id ? this.user.id : "");
        /*formData.append(
          "wellboats_id",
          this.user.wellboats_id ? this.user.wellboats_id : ""
        );*/

        //centralizacion
        formData.append(
          "wellboats_id",
          this.user.wellboat.id ? this.user.wellboat.id : ""
        );
      }

      await this.axios
        .post(url, formData, this.headers_file)
        .then((res) => {
          res.status === 200 && res.data.status === 200
            ? new Promise(() => {
              this.alert.mostrar = true;
              this.alert.tipo = "success";
              this.alert.color_boton = "green";
              this.alert.mensaje = res.data.message;
              this.data = [];
              setTimeout(() => {
                this.$router.push("solicitud_materiales");
              }, 5000);
            })
            : res.data.message &&
            new Promise(() => {
              this.alert.mostrar = true;
              this.alert.tipo = "error";
              this.alert.color_boton = "red";
              this.alert.mensaje = res.data.message;
            });
        })
        .catch((error) => {
          if (error.response.data.errors.length > 0) {
            this.alert.mostrar = true;
            this.alert.tipo = "error";
            this.alert.color_boton = "red";
            this.alert.mensaje = error.response.data.message;
            this.alert.errores = error.response.data.errors;
          }
          this.pendientes = error.response.data.pendientes;
          console.log("errores", error.response.data);
        });
      this.spinner = false;
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    agregarFila() {
      this.data.push({
        wellboat_id: "",
        areas_id: "",
        categorias_id: "",
        materiales_id: "",
        descripcion: "",
        cantidad: "",
        file: null,
      });
    },
    eliminarFila(index) {
      this.data.splice(index, 1);
    },
    selectCategoria(value) {
      this.select_categoria = value;
    },
    selectItem() {
      this.select_categoria = "";
    },
    retornar_materiales(categorias_id) {
      if (categorias_id) {
        return this.items.filter((m) => m.categorias_id == categorias_id);
      }
      return [];
    },
    modificarCategoria(item) {
      const material = this.items.find(i => i.id === item.materiales_id);
      item.categorias_id = material.categorias_id;
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
};
</script>
<style scoped>
.alert {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

.alert h3 {
  margin-top: 0;
  font-size: 18px;
}

.alert ul {
  margin: 10px 0 10px 20px;
}

.alert li {
  margin-bottom: 5px;
}
</style>
